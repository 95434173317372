import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-circunferencia-braco',
  templateUrl: './circunferencia-braco.component.html',
  styleUrls: ['./circunferencia-braco.component.scss']
})
export class CircunferenciaBracoComponent implements OnInit {

  flag: boolean = false;
  checked_feminino: boolean = false;

  circunfBraco: number;
  idade: number;

  CB: number = 0;
  classificacao: string;

  constructor(private toastr: ToastrService) { }

  ngOnInit(): void {}

  btnLimpar(): void {
    this.circunfBraco = null;
    this.idade = null;
    this.flag = false
    this.checked_feminino = false;
  }

  percentil(idade: number, sexo: boolean): number {
    if (sexo === false) {
      if (idade >= 18 && idade <= 18.9) {
        return 29.7;
      } else if (idade >= 19 && idade <= 24.9) {
        return 30.8;
      } else if (idade >= 25 && idade <= 34.9) {
        return 31.9;
      } else if (idade >= 35 && idade <= 44.9) {
        return 32.6;
      } else if (idade >= 45 && idade <= 54.9) {
        return 32.2;
      } else if (idade >= 55 && idade <= 64.9) {
        return 31.7;
      } else if (idade >= 65 && idade <= 74.9) {
        return 30.7;
      }
    } else {
      if (idade >= 18 && idade <= 18.9) {
        return 26.8;
      } else if (idade >= 19 && idade <= 24.9) {
        return 26.5;
      } else if (idade >= 25 && idade <= 34.9) {
        return 27.7;
      } else if (idade >= 35 && idade <= 44.9) {
        return 29.1;
      } else if (idade >= 45 && idade <= 54.9) {
        return 29.9;
      } else if (idade >= 55 && idade <= 64.9) {
        return 30.3;
      } else if (idade >= 65 && idade <= 74.9) {
        return 29.9;
      }
    }
  }

  calcular(circunfBraco: number, idade: number): void {
    this.CB = circunfBraco * 100 / this.percentil(idade, this.checked_feminino);
    this.flag = true;
    if (this.CB < 70) {
      this.classificacao = 'Desnutrição Energético-Proteica Grave';
    } else if (this.CB >= 70 && this.CB < 80) {
      this.classificacao = 'Desnutrição Energético-Proteica Moderada';
    } else if (this.CB >= 80 && this.CB < 90) {
      this.classificacao = 'Desnutrição Energético-Proteica Leve';
    } else if (this.CB >= 90 && this.CB < 110) {
      this.classificacao = 'Eutrófico';
    } else if (this.CB >= 110 && this.CB < 120) {
      this.classificacao = 'Sobrepeso';
    } else if (this.CB >= 120) {
      this.classificacao = 'Obesidade';
    }
  }
}
