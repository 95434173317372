import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-estimativa-altura',
  templateUrl: './estimativa-altura.component.html',
  styleUrls: ['./estimativa-altura.component.scss']
})
export class EstimativaAlturaComponent implements OnInit {

  flag: boolean = false;
  idade: number;
  altura_joelho: number;
  altura: number;

  checked_idoso: boolean = false;
  checked_negra: boolean = false;
  checked_feminino: boolean = false;

  disabled: boolean;

  constructor(private toastr: ToastrService) { }

  ngOnInit(): void {
  }

  desabilitar_negra(): void {
    if (!this.checked_idoso) {
      this.disabled = true;
      this.flag = false;
    } else {
      this.disabled = false;
      this.flag = false;
    }
  }

  ocultarCard(): void {
    this.flag = false;
  }

  btnLimpar(): void {
    this.altura_joelho = null;
    this.idade = null;
    this.flag = false;
    this.checked_feminino = false;
    this.checked_idoso = false;
    this.checked_negra = false;
    this.disabled = false;
  }

  showNotification(): void {
    this.toastr.info('<span class="tim-icons icon-bell-55" [data-notify]="icon"></span> Cálculo realizado com  <b>sucesso</b>', '', {
      disableTimeOut: false,
      timeOut: 2500,
      closeButton: false,
      enableHtml: true,
      toastClass: "alert alert-danger alert-with-icon",
      positionClass: 'toast-top-right'
    });
  }

  showError(): void {
    this.toastr.info('<span class="tim-icons icon-bell-55" [data-notify]="icon"></span> Idade tem de estar acima de <b>60 anos</b>', '', {
      disableTimeOut: false,
      timeOut: 2500,
      closeButton: false,
      enableHtml: true,
      toastClass: "alert alert-primary alert-with-icon",
      positionClass: 'toast-top-right'
    });
  }

  intervaloIdadeError(): void {
    this.toastr.info('<span class="tim-icons icon-bell-55" [data-notify]="icon"></span> Idade tem de estar entre <b>18 e 60 anos</b>', '', {
      disableTimeOut: false,
      timeOut: 2500,
      closeButton: false,
      enableHtml: true,
      toastClass: "alert alert-primary alert-with-icon",
      positionClass: 'toast-top-right'
    });
  }

  calcular(idade: number, altura_joelho: number): void {
    if (this.checked_idoso) {
      if (this.checked_feminino) {
        if (this.idade <= 60) {
          this.showError();
        } else {
          this.altura = 84.88 + (0.24 * this.idade) + (1.23 * this.altura_joelho);
          this.flag = true;
          this.showNotification();
          console.log('Chumlea idoso feminino ' + this.altura.toFixed(1));
        }
      } else {
        if (this.idade <= 60) {
          this.showError();
        } else {
          this.altura = 64.19 - (0.04 * this.idade) + (2.04 * this.altura_joelho);
          this.flag = true;
          this.showNotification();
          console.log('Chumlea idoso masculino ' + this.altura.toFixed(1));
        }
      }
    } else {
      if (this.checked_negra) {
        if (this.checked_feminino) {
          if (this.idade >= 18 && this.idade <= 60) {
            this.altura = 68.10 + (1.86 * this.altura_joelho) - (0.06 * this.idade);
            this.flag = true;
            this.showNotification();
            console.log('Adulto negro feminino');
          } else {
            this.intervaloIdadeError();
          }
        } else {
          if (this.idade >= 18 && this.idade <= 60) {
            this.altura = 73.42 + (1.79 * this.altura_joelho);
            this.flag = true;
            this.showNotification();
            console.log('adulto negro masculino');
          } else {
            this.intervaloIdadeError();
          }
        }
      } else {
        if (this.checked_feminino) {
          if (this.idade >= 18 && this.idade <=60) {
            this.altura = 70.25 + (1.87 * this.altura_joelho) - (0.06 * this.idade);
            this.flag = true;
            this.showNotification();
          } else {
            this.intervaloIdadeError();
          }
        } else {
          if (this.idade >= 18 && this.idade <= 60) {
            this.altura = 71.85 + 1.88 * this.altura_joelho;
            this.flag = true;
            this.showNotification();
          } else {
            this.intervaloIdadeError();
          }
        }
      }
    }
  }

}
