import { Component, OnInit } from "@angular/core";
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: "app-etapa-dois",
  templateUrl: "./etapa-dois.component.html",
  styleUrls: ["./etapa-dois.component.scss"],
})
export class EtapaDoisComponent implements OnInit {

  estadoNutricional: number = 0;
  gravidadeDoenca: number = 0;
  idade: string = "";
  score: number = 0;
  score2: number = 0;

  flagEstado: boolean = false;
  flagGravidade: boolean = false;
  desabilitado: boolean = true;
  checked: boolean = false;
  flag: boolean = false;
  diagnosticoNSR: string = "";
  diagnosticoGravidade: string = "";

  constructor(private toastr: ToastrService) {}

  ngOnInit(): void {}

  escolhaEstado() {
    this.flagEstado = true;
    if (this.flagEstado == true && this.flagGravidade == true) {
      this.desabilitado = false;
    }
  }

  escolhaGravidade() {
    this.flagGravidade = true;
    if (this.flagEstado == true && this.flagGravidade == true) {
      this.desabilitado = false;
    }
  }

  showNotification(): void {
    this.toastr.info('<span class="tim-icons icon-bell-55" [data-notify]="icon"></span> Cálculo realizado com  <b>sucesso</b>', '', {
      disableTimeOut: false,
      timeOut: 2500,
      closeButton: false,
      enableHtml: true,
      toastClass: "alert alert-danger alert-with-icon",
      positionClass: 'toast-top-right'
    });
  }

  limpar(): void {

  }

  scroll(el: HTMLElement) {
    el.scrollIntoView();
}

  resultado() {
    console.log('Estado Nutricional ', this.estadoNutricional);
    console.log('Gravidade da doença ', this.gravidadeDoenca);

    this.diagnosticoNSR = "";
    this.diagnosticoGravidade = "";
    if (this.estadoNutricional == 0) {
      this.score = 0;
    } else if (this.estadoNutricional == 1) {
      this.score = 1;
    } else if (this.estadoNutricional == 2) {
      this.score = 2;
    } else if (this.estadoNutricional == 3) {
      this.score = 3;
    }
    if (this.gravidadeDoenca == 0) {
      this.score2 = 0;
    } else if (this.gravidadeDoenca == 1) {
      this.score2 = 1;
    } else if (this.gravidadeDoenca == 2) {
      this.score2 = 2;
    } else if (this.gravidadeDoenca == 3) {
      this.score2 = 3;
    }
    if (this.checked == true) {
      this.flag = true;
      this.score = this.score + this.score2 + 1;
      if (this.score < 3) {
        this.diagnosticoNSR =
          "Sem risco nutricional. Reavaliar o paciente semanalmente.";
      } else if (this.score >= 3) {
        this.diagnosticoNSR =
          "Em risco nutricional. Realizar avaliação nutricional completa, e o cuidado nutricional deve ser iniciado.";
      }
      if (this.score2 == 1) {
        this.diagnosticoGravidade =
          "Necessidade proteica aumentada. Déficit proteico pode ser recuperado pela VO ou com suplementos VO.";
      } else if (this.score2 == 2) {
        this.diagnosticoGravidade =
          "Necessidade proteica substancialmente aumentada. Déficit proteico pode ser recuperado com suplementos VO.";
      } else if (this.score2 == 3) {
        this.diagnosticoGravidade =
          "Necessidade proteica substancialmente aumentada. Déficit proteico não pode ser recuperado somente pelo uso de suplementos VO, possui indicação de dieta enteral (KRONDRUP, 2003).";
      }
      console.log("60 anos", this.score2);
      this.showNotification();
    } else {
      this.score = this.score + this.score2;
      this.flag = true;
      if (this.score < 3) {
        this.diagnosticoNSR =
          "Sem risco nutricional. Reavaliar o paciente semanalmente.";
      } else if (this.score >= 3) {
        this.diagnosticoNSR =
          "Em risco nutricional. Realizar avaliação nutricional completa, e o cuidado nutricional deve ser iniciado.";
      }
      if (this.score2 == 1) {
        this.diagnosticoGravidade =
          "Necessidade proteica aumentada. Déficit proteico pode ser recuperado pela VO ou com suplementos VO.";
      } else if (this.score2 == 2) {
        this.diagnosticoGravidade =
          "Necessidade proteica substancialmente aumentada. Déficit proteico pode ser recuperado com suplementos VO.";
      } else if (this.score2 == 3) {
        this.diagnosticoGravidade =
          "Necessidade proteica substancialmente aumentada. Déficit proteico não pode ser recuperado somente pelo uso de suplementos VO, possui indicação de dieta enteral (KRONDRUP, 2003).";
      }
      console.log(this.score2);
      this.showNotification();
    }
  }
}
