import { Component, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-estimativa-peso-ideal",
  templateUrl: "./estimativa-peso-ideal.component.html",
  styleUrls: ["./estimativa-peso-ideal.component.scss"],
})
export class EstimativaPesoIdealComponent implements OnInit {
  altura: number;
  idade: number;
  peso_ideal: number;
  flag: boolean;

  checked_idoso: boolean = false;
  checked_feminino: boolean = false;

  disabled: boolean = true;
  fonte: boolean = false;

  constructor(private toastr: ToastrService) {}

  ngOnInit(): void {}

  habilitarIdade(): void {
    this.disabled = !this.disabled;
    this.flag = false;
  }

  habilitarFonte(): void {
    this.fonte = true;
  }

  ocultarCard(): void {
    this.flag = false;
  }

  btnLimpar(): void {
    this.idade = null;
    this.altura = null;
    this.flag = false;
    this.checked_idoso = false;
    this.disabled = true;
    this.checked_feminino = false;
  }

  showNotification(): void {
    this.toastr.info(
      '<span class="tim-icons icon-bell-55" [data-notify]="icon"></span> Cálculo realizado com  <b>sucesso</b>',
      "",
      {
        disableTimeOut: false,
        timeOut: 2500,
        closeButton: false,
        enableHtml: true,
        toastClass: "alert alert-danger alert-with-icon",
        positionClass: "toast-top-right",
      }
    );
  }

  showError(): void {
    this.toastr.info(
      '<span class="tim-icons icon-bell-55" [data-notify]="icon"></span>Idade tem de estar igual ou acima de <b>65 anos</b>',
      "",
      {
        disableTimeOut: false,
        timeOut: 2500,
        closeButton: false,
        enableHtml: true,
        toastClass: "alert alert-primary alert-with-icon",
        positionClass: "toast-top-right",
      }
    );
  }

  calcular(altura: number, idade?: number): void {
    if (this.checked_idoso) {
      if (this.checked_feminino) {
        if (this.idade >= 65) {
          this.peso_ideal =
            this.percentil(idade, this.checked_feminino) *
            (this.altura * this.altura);
          this.flag = true;
          this.showNotification();
          console.log("Idoso feminino");
        } else {
          this.showError();
        }
      } else {
        if (this.idade >= 65) {
          this.peso_ideal =
            this.percentil(idade, this.checked_feminino) *
            (this.altura * this.altura);
          this.flag = true;
          this.showNotification();
          console.log("Idoso masculino");
        } else {
          this.showError();
        }
      }
    } else {
      if (this.checked_feminino) {
        this.peso_ideal = 21 * (this.altura * this.altura);
        this.flag = true;
        this.showNotification();
        console.log("Não idoso feminino");
      } else {
        this.peso_ideal = 22 * (this.altura * this.altura);
        this.flag = true;
        this.showNotification();
        console.log("Não idoso masculino");
      }
    }
  }

  percentil(idade: number, sexo: boolean): number {
    if (sexo === false) {
      if (idade >= 65 && idade <= 69) {
        return 24.3;
      } else if (idade >= 70 && idade <= 74) {
        return 25.1;
      } else if (idade >= 75 && idade <= 79) {
        return 23.9;
      } else if (idade >= 80 && idade <= 84) {
        return 23.7;
      } else if (idade > 85) {
        return 23.1;
      }
    } else {
      if (idade >= 65 && idade <= 69) {
        return 26.5;
      } else if (idade >= 70 && idade <= 74) {
        return 26.3;
      } else if (idade >= 75 && idade <= 79) {
        return 26.1;
      } else if (idade >= 80 && idade <= 84) {
        return 25.5;
      } else if (idade > 85) {
        return 23.6;
      }
    }
  }
}
