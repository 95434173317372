import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-perda-ponderal',
  templateUrl: './perda-ponderal.component.html',
  styleUrls: ['./perda-ponderal.component.scss']
})
export class PerdaPonderalComponent implements OnInit {

  flag: boolean = false;

  pesoHabitual: number;
  pesoAtual: number;
  selected: string = '';
  perdaPonderal: number;

  classificacao: string;

  constructor(private toastr: ToastrService) { }

  ngOnInit(): void {
  }

  btnLimpar(): void {
    this.pesoHabitual = null;
    this.pesoAtual = null;
    this.flag = false;
    this.selected = '';
  }

  showNotification(): void {
    this.toastr.info('<span class="tim-icons icon-bell-55" [data-notify]="icon"></span> Cálculo realizado com  <b>sucesso</b>', '', {
      disableTimeOut: false,
      timeOut: 2500,
      closeButton: false,
      enableHtml: true,
      toastClass: "alert alert-danger alert-with-icon",
      positionClass: 'toast-top-right'
    });
  }


  calcular(pesoHabitual: number, pesoAtual: number, selected: string): void {
    console.log('Valor selecionado', this.selected);
    if (pesoHabitual <= pesoAtual) {
      this.perdaPonderal = 0;
      this.classificacao = "Não houve perda de peso";
      this.flag = true;
      this.showNotification();
      console.log(this.perdaPonderal, this.classificacao);
      return
    }
    this.perdaPonderal = (pesoHabitual - pesoAtual) * 100 / pesoHabitual;
    if (selected == '1') {
      console.log('entrou1')
      if (this.perdaPonderal >= 1 && this.perdaPonderal <= 2) {
        this.classificacao = 'Perda significativa de peso %';
      } else if (this.perdaPonderal > 2) {
        this.classificacao = 'Perda grave de peso %';
      } else {
        this.classificacao = 'Sem perda considerável';
      }
    } else if (selected == '2') {
      console.log('entrou2')
      if (this.perdaPonderal == 5) {
        this.classificacao = 'Perda significativa de peso %';
      } else if (this.perdaPonderal > 5) {
        this.classificacao = 'Perda grave de peso %';
      } else {
        this.classificacao = 'Sem perda considerável';
      }
    } else if (selected == '3') {
      console.log('entrou3')
      if (this.perdaPonderal == 7.5) {
        this.classificacao = 'Perda significativa de peso %';
      } else if (this.perdaPonderal > 7.5) {
        console.log('entrou')
        this.classificacao = 'Perda grave de peso %';
      } else {
        this.classificacao = 'Sem perda considerável';
      }
    } else if (selected == '4') {
      console.log('entrou4')
      if (this.perdaPonderal == 10) {
        this.classificacao = 'Perda significativa de peso %';
      } else if (this.perdaPonderal > 10) {
        this.classificacao = 'Perda grave de peso %';
      } else {
        this.classificacao = 'Sem perda considerável';
      }
    }
    console.log('Esse é o valor',this.selected);
    this.flag = true;
    this.showNotification();
  }

}
