import { Component, OnInit } from "@angular/core";

declare interface RouteInfo {
  path: string;
  title: string;
  rtlTitle: string;
  icon: string;
  class: string;
}
export const ROUTES: RouteInfo[] = [
  {
    path: "/dashboard",
    title: "Home",
    rtlTitle: "",
    icon: "icon-istanbul",
    class: ""
  },
  {
    path: "/imc",
    title: "Indice de Massa Corporal",
    rtlTitle: "",
    icon: "icon-chart-bar-32",
    class: ""
  },
  {
    path: "/altura",
    title: "Estimativa Altura",
    rtlTitle: "خرائط",
    icon: "icon-notes",
    class: ""
  },
  {
    path: "/estimativa_peso",
    title: "Estimativa do Peso",
    rtlTitle: "إخطارات",
    icon: "icon-paper",
    class: ""
  },

  {
    path: "/estimativa_peso_ideal",
    title: "Estimativa do Peso Ideal",
    rtlTitle: "ملف تعريفي للمستخدم",
    icon: "icon-wallet-43",
    class: ""
  },
  {
    path: "/perda_ponderal",
    title: "Perda Ponderal",
    rtlTitle: "قائمة الجدول",
    icon: "icon-credit-card",
    class: ""
  },
  {
    path: "/etapa-um",
    title: "Triagem de Risco Nutricional",
    rtlTitle: "طباعة",
    icon: "icon-mobile",
    class: ""
  },
  {
    path: "/circun-braco",
    title: "Circunferência do Braço",
    rtlTitle: "طباعة",
    icon: "icon-chart-pie-36",
    class: ""
  },
  {
    path: "/glim",
    title: "Diagnóstico GLIM",
    rtlTitle: "طباعة",
    icon: "icon-vector",
    class: ""
  },
  {
    path: "/harris",
    title: "Harris and Benedict",
    rtlTitle: "طباعة",
    icon: "icon-bullet-list-67",
    class: ""
  },
  {
    path: "/harris",
    title: "Outro",
    rtlTitle: "طباعة",
    icon: "icon-bullet-list-67",
    class: ""
  }
];

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.css"]
})
export class SidebarComponent implements OnInit {
  menuItems: any[];

  constructor() {}

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
  }
  isMobileMenu() {
    if (window.innerWidth > 991) {
      return false;
    }
    return true;
  }
}
