import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { RouterModule } from "@angular/router";
import { ToastrModule } from 'ngx-toastr';

import { AppComponent } from "./app.component";
import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

import { AppRoutingModule } from "./app-routing.module";
import { ComponentsModule } from "./components/components.module";
import { EstimativaAlturaComponent } from './pages/estimativa-altura/estimativa-altura.component';
import { EstimativaPesoComponent } from './pages/estimativa-peso/estimativa-peso.component';
import { EstimativaPesoIdealComponent } from './pages/estimativa-peso-ideal/estimativa-peso-ideal.component';
import { PerdaPonderalComponent } from './pages/perda-ponderal/perda-ponderal.component';
import { EtapaUmComponent } from './triagem/etapa-um/etapa-um.component';
import { EtapaDoisComponent } from './triagem/etapa-dois/etapa-dois.component';
import { CircunferenciaBracoComponent } from './pages/circunferencia-braco/circunferencia-braco.component';
import { GlimComponent } from './pages/glim/glim.component';
import { HarrisComponent } from './pages/harris/harris.component';




@NgModule({
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    ComponentsModule,
    NgbModule,
    RouterModule,
    AppRoutingModule,
    ToastrModule.forRoot()
  ],
  declarations: [AppComponent, AdminLayoutComponent, AuthLayoutComponent, EstimativaAlturaComponent, EstimativaPesoComponent, EstimativaPesoIdealComponent, PerdaPonderalComponent, EtapaUmComponent, EtapaDoisComponent, CircunferenciaBracoComponent, GlimComponent, HarrisComponent],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {}
