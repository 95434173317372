import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-harris',
  templateUrl: './harris.component.html',
  styleUrls: ['./harris.component.scss']
})
export class HarrisComponent implements OnInit {

  flag: boolean = false;
  pesoHabitual: number;
  altura: number;
  idade: number;
  selected: string = '';
  selectedInjuria: string = '';
  selectedTermico: string = '';
  fatorAtividade: number;
  fatorInjuria: number;
  fatorTermico: number;
  gastoEnergeticoBasal: number;
  gastoEnergeticoTotal: number;

  checked_feminino: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  btnLimpar(): void {
    location.reload();
  }

  scrollToElement($element): void {
    console.log($element);
    $element.scrollIntoView({behavior: "smooth", block: "start", inline: "end"});
  }

  calcular(pesoHabitual: number, altura: number, idade: number, checked_feminino: boolean, selected: string, selectedInjuria: string, selectedTermico: string): void {
    console.log('Valor selecionado', this.selected);
    console.log('Valor selecionado', this.fatorInjuria);
    this.flag = true;
    if (checked_feminino === false) {
      this.gastoEnergeticoBasal = 66.5 + (13.8 * pesoHabitual) + (5 * altura) - (6.78 * idade);
      console.log(this.gastoEnergeticoBasal);
    } else {
      this.gastoEnergeticoBasal = 655.1 + (9.6 * pesoHabitual) + (1.85 * altura) - (4.7 * idade);
      console.log(this.gastoEnergeticoBasal);
    }

    if (selected == "1") {
      this.fatorAtividade = 1.20;
    } else if (selected == "2") {
      this.fatorAtividade = 1.25;
    } else if (selected == "3") {
      this.fatorAtividade = 1.30
    } else {
      this.fatorAtividade = 1;
    }
    if (selectedInjuria == "1") {
      this.fatorInjuria = 1;
    } else if (selectedInjuria == "2") {
      this.fatorInjuria = 1.02;
    } else if (selectedInjuria == "3") {
      this.fatorInjuria = 1.07;
    } else if (selectedInjuria == "4") {
      this.fatorInjuria = 1.17;
    } else if (selectedInjuria == "5") {
      this.fatorInjuria = 1.40;
    } else if (selectedInjuria == "6") {
      this.fatorInjuria = 1.20;
    } else if (selectedInjuria == "7") {
      this.fatorInjuria = 1.20;
    } else if (selectedInjuria == "8") {
      this.fatorInjuria = 1.40;
    } else if (selectedInjuria == "9") {
      this.fatorInjuria = 1.50;
    } else if (selectedInjuria == "10") {
      this.fatorInjuria = 1.60;
    } else if (selectedInjuria == "11") {
      this.fatorInjuria = 1.70;
    } else if (selectedInjuria == "12") {
      this.fatorInjuria = 1.50;
    } else if (selectedInjuria == "13") {
      this.fatorInjuria = 1.60;
    } else if (selectedInjuria == "14") {
      this.fatorInjuria = 1.10;
    } else if (selectedInjuria == "15") {
      this.fatorInjuria = 1.20;
    } else if (selectedInjuria == "16") {
      this.fatorInjuria = 1.20;
    } else if (selectedInjuria == "17") {
      this.fatorInjuria = 1.45;
    } else if (selectedInjuria == "18") {
      this.fatorInjuria = 1.42;
    } else if (selectedInjuria == "19") {
      this.fatorInjuria = 1.20;
    } else if (selectedInjuria == "20") {
      this.fatorInjuria = 1.17;
    } else if (selectedInjuria == "21") {
      this.fatorInjuria = 1.40;
    } else if (selectedInjuria == "22") {
      this.fatorInjuria = 1.25;
    } else if (selectedInjuria == "23") {
      this.fatorInjuria = 1.30;
    } else if (selectedInjuria == "24") {
      this.fatorInjuria = 1.45;
    } else if (selectedInjuria == "25") {
      this.fatorInjuria = 1.30;
    } else if (selectedInjuria == "26") {
      this.fatorInjuria = 1.45;
    } else {
      this.fatorInjuria = 1;
    }

    if (selectedTermico == "0") {
      this.fatorTermico = 1;
    } else if (selectedTermico == "1") {
      this.fatorTermico = 1.10;
    } else if (selectedTermico == "2") {
      this.fatorTermico = 1.20;
    } else if (selectedTermico == "3") {
      this.fatorTermico = 1.30;
    } else if (selectedTermico == "4") {
      this.fatorTermico = 1.40;
    } else {
      this.fatorTermico = 1;
    }

    console.log("fator Atividade", this.fatorAtividade);
    console.log("fator injuria", this.fatorInjuria);
    console.log("fator termico", this.fatorTermico);
    this.gastoEnergeticoTotal = this.gastoEnergeticoBasal * this.fatorAtividade * this.fatorInjuria * this.fatorTermico;
    console.log(this.gastoEnergeticoTotal);

  }

}
