import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-estimativa-peso',
  templateUrl: './estimativa-peso.component.html',
  styleUrls: ['./estimativa-peso.component.scss']
})
export class EstimativaPesoComponent implements OnInit {

  flag: boolean = false;
  idade: number;
  altura_joelho: number;
  circunfBraco: number;
  peso_estimado: number;

  checked_idoso: boolean = false;
  checked_negra: boolean = false;
  checked_feminino: boolean = false;

  disabled: boolean;

  constructor(private toastr: ToastrService) { }

  ngOnInit(): void {
  }

  ocultarCard(): void {
    this.flag = false;
  }

  btnLimpar(): void {
    this.altura_joelho = null;
    this.idade = null;
    this.circunfBraco = null;
    this.flag = false;
    this.checked_feminino = false;
    this.checked_idoso = false;
    this.checked_negra = false;
    this.disabled = false;
  }

  showNotification(): void {
    this.toastr.info('<span class="tim-icons icon-bell-55" [data-notify]="icon"></span> Cálculo realizado com  <b>sucesso</b>', '', {
      disableTimeOut: false,
      timeOut: 2500,
      closeButton: false,
      enableHtml: true,
      toastClass: "alert alert-danger alert-with-icon",
      positionClass: 'toast-top-right'
    });
  }

  showError(): void {
    this.toastr.info('<span class="tim-icons icon-bell-55" [data-notify]="icon"></span> Idade tem de estar acima de <b>59 anos</b>', '', {
      disableTimeOut: false,
      timeOut: 2500,
      closeButton: false,
      enableHtml: true,
      toastClass: "alert alert-primary alert-with-icon",
      positionClass: 'toast-top-right'
    });
  }

  calcular(idade: number, altura_joelho: number, circunfBraco: number): void {
    if (this.checked_idoso) {
      if (this.checked_feminino) {
        if (this.checked_negra) {
          if (this.idade <= 59) {
            this.showError();
          } else {
            this.peso_estimado = (this.altura_joelho * 1.50) + (this.circunfBraco * 2.58) - 84.22;
            this.flag = true;
            this.showNotification();
            console.log('peso estimado idoso feminino negro' + this.peso_estimado.toFixed(1));
          }
        } else {
          if (this.idade <= 59) {
            this.showError();
          } else {
            this.peso_estimado = (this.altura_joelho * 1.09) + (this.circunfBraco * 2.68) - 65.51
            this.flag = true;
            this.showNotification();
            console.log('peso estimado idoso feminino branco' + this.peso_estimado.toFixed(1));
          }
        }
      } else {
        if (this.checked_negra) {
          if (this.idade <= 59) {
            this.showError();
          } else {
            this.peso_estimado = (this.altura_joelho * 0.44) + (this.circunfBraco * 2.86) - 39.21;
            this.flag = true;
            this.showNotification();
            console.log('peso estimado idoso masculino negro' + this.peso_estimado.toFixed(1));
          }
        } else {
          if (this.idade <= 59) {
            this.showError();
          } else {
            this.peso_estimado = (this.altura_joelho * 1.10) + (this.circunfBraco * 3.07) - 75.81;
            this.flag = true;
            this.showNotification();
            console.log('peso estimado idoso masculino branco' + this.peso_estimado.toFixed(1));
          }
        }
      }
    } else {
      if (this.checked_feminino) {
        if (this.checked_negra) {
          if (this.idade >= 19 && this.idade <= 59) {
            this.peso_estimado = (this.altura_joelho * 1.24) + (this.circunfBraco * 2.97) - 82.48;
            this.flag = true;
            this.showNotification();
            console.log('Não Idoso feminino negro');
          } else {
            this.showError();
          }
        } else {
          if (this.idade >= 19 && this.idade <= 59) {
            this.peso_estimado = (this.altura_joelho * 1.01) + (this.circunfBraco * 2.81) - 66.04;
            this.flag = true;
            this.showNotification();
            console.log('Não Idoso feminino branco');
          } else {
            this.showError();
          }
        }
      } else {
        if (this.checked_negra) {
          if (this.idade >= 19 && this.idade <= 59) {
            this.peso_estimado = (this.altura_joelho * 1.09) + (this.circunfBraco * 3.14) - 83.72;
            this.flag = true;
            this.showNotification();
            console.log('Não idoso masculino negro');
          } else {
            this.showError();
          }
        } else {
          if (this.idade >= 19 && this.idade <= 59) {
            this.peso_estimado = (this.altura_joelho * 1.19) + (this.circunfBraco * 3.21) - 86.82;
            this.flag = true;
            this.showNotification();
            console.log('Não idoso masculino branco');
          } else {
            this.showError();
          }
        }
      }
    }
  }

}
