import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-glim',
  templateUrl: './glim.component.html',
  styleUrls: ['./glim.component.scss']
})
export class GlimComponent implements OnInit {

  constructor(private toastr: ToastrService) { }

  flag: boolean;
  flag1: boolean;
  criterioFenotipico1_checked: boolean = false;
  criterioFenotipico2_checked: boolean = false;
  criterioFenotipico3_checked: boolean = false;
  criterioEtiologico1_checked: boolean = false;
  criterioEtiologico2_checked: boolean = false;

  resultadoFenotipico: boolean = false;
  resultadoEtiologico: boolean = false;

  ngOnInit(): void {
  }

  ajudaPerdaPeso(): void {
    this.toastr.info('<span class="tim-icons icon-bell-55" [data-notify]="icon"></span>&#x02666;> 5% nos últimos 6 meses ou >10% em mais de 6 meses', '', {
      disableTimeOut: true,
      timeOut: 3500,
      closeButton: true,
      enableHtml: true,
      toastClass: "alert alert-primary alert-with-icon",
      positionClass: 'toast-top-right'
    });
  }

  ajudaBaixoImc(): void {
    this.toastr.info('<span class="tim-icons icon-bell-55" [data-notify]="icon"></span>&#x02666; <20 em <70 anos ou <22 em >70 anos <br> &#x02666; Ásia: <18,5 em <70 anos ou <20 em >70 anos',
     '', {
      disableTimeOut: true,
      timeOut: 7500,
      closeButton: true,
      enableHtml: true,
      toastClass: "alert alert-primary alert-with-icon",
      positionClass: 'toast-top-right'
    });
  }

  ajudaMassaMuscular(): void {
    this.toastr.info('<span class="tim-icons icon-bell-55" [data-notify]="icon"></span><p align="justify"> &#x02666; DEXA, BIA, Ultrassom, Tomografia Computadorizada, Ressonância Magnética. Se indisponíveis – circunferência da panturrilha e circunferência muscular do braço.<br> &#x02666; Força de pressão palmar pode ser utilizada como medida de apoio.</p>',
     '', {
      disableTimeOut: true,
      timeOut: 7500,
      closeButton: true,
      enableHtml: true,
      toastClass: "alert alert-primary alert-with-icon",
      positionClass: 'toast-top-right'
    });
  }

  ajudaReducaoIngesta(): void {
    this.toastr.info('<span class="tim-icons icon-bell-55" [data-notify]="icon"></span><p align="justify"> &#x02666; <50% das necessidades por mais de 1 semana ou <br> &#x02666; Qualquer redução alimentar por mais de 2 semanas ou <br>&#x02666; Condição gastrintestinal que altera a assimilação/absorção de nutrientes.<br> (Doenças: Síndrome do intestino curto, insuficiência pancreática e Pós cirurgia bariátrica. <br>Distúrbios: estenoses esofágicas, gastroparesia e pseudo-obstrução intestinal. <br>Sintomas: disfagia, náusea, vômito, diarreia, constipação e dor abdominal)</p>',
     '', {
      disableTimeOut: true,
      timeOut: 7500,
      closeButton: true,
      enableHtml: true,
      tapToDismiss: true,
      toastClass: "alert alert-primary alert-with-icon",
      positionClass: 'toast-top-right'
    });
  }

  ajudaGravidadeDoenca(): void {
    this.toastr.info('<span class="tim-icons icon-bell-55" [data-notify]="icon"></span><p align="justify"> &#x02666; Seguir critérios estabelecidos nas ferramentas de triagem nutricional.<br><br>Indicadores de inflamação: febre, balanço nitrogenado negativo, gasto energético de repouso elevado, alteração em PCR, albumina e pré-albumina.<br><br>&#x02666; Avaliar: Doença crônica ou aguda.</p>',
     '', {
      disableTimeOut: true,
      timeOut: 7500,
      closeButton: true,
      enableHtml: true,
      tapToDismiss: true,
      toastClass: "alert alert-primary alert-with-icon",
      positionClass: 'toast-top-right'
    });
  }

  scrollToElement($element): void {
    console.log($element);
    $element.scrollIntoView({behavior: "smooth", block: "start", inline: "end"});
  }

  limpar(): void {
    this.criterioFenotipico1_checked = false;
    this.criterioFenotipico2_checked = false;
    this.criterioFenotipico3_checked = false;
    this.criterioEtiologico1_checked = false;
    this.criterioEtiologico2_checked = false;

    this.resultadoFenotipico = false;
    this.resultadoEtiologico = false;

    this.flag = false;
    this.flag1 = false;
  }

  resultado(): void {
    this.flag = true;
    this.flag1 = false;
    this.resultadoFenotipico = false;
    this.resultadoEtiologico = false;

    if (this.criterioFenotipico1_checked || this.criterioFenotipico2_checked || this.criterioFenotipico3_checked) {
      this.resultadoFenotipico = true;
    }
    if (this.criterioEtiologico1_checked || this.criterioEtiologico2_checked) {
      this.resultadoEtiologico = true;
    }

    if (this.resultadoEtiologico == true && this.resultadoFenotipico == true) {
      this.flag = true;
      this.flag1 = false;
    } else {
      this.flag = false;
      this.flag1 = true;
    }

    console.log(this.resultadoFenotipico);
    console.log(this.resultadoEtiologico);
  }

}
