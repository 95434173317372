import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-etapa-um',
  templateUrl: './etapa-um.component.html',
  styleUrls: ['./etapa-um.component.scss']
})
export class EtapaUmComponent implements OnInit {

  contador1: string;
  contador2: string;
  contador3: string;
  contador4: string;
  contador5: string;

  flag: boolean = false;



  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  avancar() {

    if (this.contador1 === 'sim' || this.contador2 === 'sim' || this.contador3 === 'sim' || this.contador4 === 'sim' || this.contador5 === 'sim') {
      console.log('Segunda parte');
      this.router.navigate(['etapa-dois']);
    } else {
      this.flag = true;
      window.scrollTo(0,document.body.scrollHeight);
    }
  }

  limpar(): void {
    window.location.reload();
  }


}
